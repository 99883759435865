import React from 'react';
import Navbar from './Navbar'; // Adjust the import path if necessary
import './ContactUs.css'; // Custom CSS file for additional styles
import Footer from './Footer';

const ContactUs = () => {
  return (
    <>
      <Navbar id="navbar" />
      <div className="contact-us-background">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card text-center p-4 shadow-lg border-0">
                <div className="card-body">
                  <h1 className="mb-4">Contact Us</h1>
                  <p className="mb-4">
                    We would love to hear from you! Please fill out the form below to get in touch with us. Whether you have questions, feedback, or need assistance with our services, our team is here to help.
                  </p>
                  <div className="form-container">
                    <iframe
                      src="https://docs.google.com/forms/d/e/1FAIpQLSeSK_NahmROphE8yXpxespIM33k5avfEs4sW3eowbxg4pcVLw/viewform?embedded=true"
                      width="100%"
                      height="600"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Contact Us Form"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
