import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    const video = videoRef.current;
    video.play();
    setIsPlaying(true);
    video.addEventListener('ended', () => {
      navigate('/home');
    });
  };

  return (
    <div className="landing-page">
      <video
        ref={videoRef}
        className={`landing-video ${isPlaying ? 'playing' : ''}`}
        playsInline
        webkit-playsinline="true"
      >
        <source src="/videos/omnamo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <div
          className="video-thumbnail"
          onClick={handlePlayVideo}
        >
          <img src="/images/thumbnail.jpg" alt="Video Thumbnail" className="thumbnail-image" />
          <div className="play-button-container">
            <div className="play-button"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
